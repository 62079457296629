import {
  // eslint-disable-next-line no-restricted-imports
  api,
  AutoLoginRequestApiDto,
  ErrorResponseApiDto,
  LoginCheckPropertiesFailedException,
  LoginRequestApiDto,
  queryClient,
  UpdateCustomerRequiredFieldsRequestApiDto,
} from '@b2x/storefront-api-js-client/src';
import {
  GetCustomerByTokenOptions,
  GetSessionOptions,
  UpdateCustomerRequiredFieldsOptions,
} from '@b2x/storefront-api-js-client/src/session';
import { AxiosError } from 'axios';
import qs from 'qs';
import React from 'react';

import { analytics } from '../analytics/analytics';
import { useAppStaticContext } from '../AppContext';
import { appConfig } from '../config';
import { useNavigate } from '../router/router';
import { storage } from '../storage';
import { ApiRequestConfig, useApiRequest } from './useApiRequest';

export const useSessionApi = () => {
  const { apiRequest } = useApiRequest();
  const { getFixedPagePath, setSession } = useAppStaticContext();
  const navigate = useNavigate();

  const getSession = React.useCallback(
    (options: GetSessionOptions = appConfig.api?.sessionOptions ?? {}, config?: ApiRequestConfig) =>
      apiRequest(api.session.get(options), { ...config }).then((response) => {
        response.data.sessionToken &&
          storage.setString('sessionToken', response.data.sessionToken, appConfig.persistentSession);
        // window.sessionStorage.setItem('userLogged', JSON.stringify(response.data.userLogged));
        // Non faccio qui una setSession in quanto il chiamante può aver fatto una populate particolare.
        // Sarà quindi lui a dover fare la setSession "parziale" facendo il merge con lo stato precedente.
        return response;
      }),
    [apiRequest]
  );

  const login = React.useCallback(
    (data: LoginRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.session.login(data), {
        silent: false,
        suppressErrorModal: ['api.error.LoginCheckPropertiesFailedException'],
        ...config,
      })
        .then((response) => {
          queryClient.clear();
          getSession(undefined, { silent: false }).then((sessionResponse) => {
            setSession(sessionResponse.data);
            data.username && analytics.events.login(response.eventId, data.username);
          });
          return response;
        })
        .catch((error: AxiosError<ErrorResponseApiDto>) => {
          if (error.response?.data.key === 'api.error.LoginCheckPropertiesFailedException') {
            // navigate verso la pagina di completamento dati utente, passandogli come queryparam i checkFailedCodes (CHECK_LOGIN_GIGYA)
            // questa pagina, di piattaforma, riceve come prop dallo store, il form da mostrare per ogni checkFailedCode.
            const errorData: ErrorResponseApiDto<LoginCheckPropertiesFailedException> = error.response.data;
            // navigate(`${getFixedPagePath('update-customer-required-fields')}?token=${errorData.args?.token}`);
            navigate({
              pathname: getFixedPagePath('update-customer-required-fields'),
              search: qs.stringify(
                { checkFailedCodes: errorData.args?.checkFailedCodes, token: errorData.args?.token },
                {
                  indices: false,
                  skipNulls: true,
                }
              ),
            });
          }
          throw error;
        }),
    [apiRequest, getFixedPagePath, getSession, navigate, setSession]
  );

  const logout = React.useCallback(
    (config?: ApiRequestConfig) =>
      apiRequest(api.session.logout(), { silent: false, ...config }).then((response) => {
        queryClient.clear();
        getSession(undefined, { silent: false }).then((sessionResponse) => {
          setSession(sessionResponse.data);
          navigate('/');
        });
        return response;
      }),
    [apiRequest, getSession, navigate, setSession]
  );

  const autologin = React.useCallback(
    (data: AutoLoginRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.session.autologin(data), { silent: false, ...config }).then((response) => {
        getSession(undefined, { silent: false }).then((sessionResponse) => {
          setSession(sessionResponse.data);
          navigate('/');
        });
        return response;
      }),
    [apiRequest, getSession, navigate, setSession]
  );

  const getCustomerByToken = React.useCallback(
    (token: string, options?: GetCustomerByTokenOptions, config?: ApiRequestConfig) =>
      apiRequest(api.session.getCustomerByToken(token, options), { silent: false, ...config }),
    [apiRequest]
  );

  const updateCustomerRequiredFields = React.useCallback(
    (
      data: UpdateCustomerRequiredFieldsRequestApiDto,
      options?: UpdateCustomerRequiredFieldsOptions,
      config?: ApiRequestConfig
    ) =>
      apiRequest(api.session.updateCustomerRequiredFields(data, options), { silent: false, ...config }).then(
        (response) => {
          data.customerRequestApiDto.email &&
            analytics.events.login(response.eventId, data.customerRequestApiDto.email);
          getSession(undefined, { silent: false }).then((sessionResponse) => {
            setSession(sessionResponse.data);
            // navigate('/');
          });
          return response;
        }
      ),
    [apiRequest, getSession, setSession]
  );

  return { autologin, getCustomerByToken, getSession, login, logout, updateCustomerRequiredFields };
};
