import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Link as _Link, LinkProps as _LinkProps, To, useLocation } from 'react-router-dom-6';

import { useAppStaticContext } from '../AppContext';
import { useModalCloser } from '../useModalCloser';
import { scrollTop as _scrollTop, wait } from '../util';

export interface LinkProps extends Omit<_LinkProps, 'to'> {
  closeModal?: boolean;
  preferFrom?: boolean;
  scrollTop?: boolean;
  silent?: boolean;
  to?: To;
}

export const Link = ({
  children,
  closeModal,
  onClick,
  preferFrom,
  scrollTop = true,
  silent,
  to,
  ...otherProps
}: LinkProps) => {
  const { setPageReady } = useAppStaticContext();
  const { pathname, search, state } = useLocation();
  const modalCloser = useModalCloser();

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (pathname !== to) {
        onClick && onClick(event);
        !silent && setPageReady(false);
        scrollTop && wait(150).then(() => _scrollTop());
      }
      closeModal && modalCloser();
    },
    [closeModal, modalCloser, onClick, pathname, scrollTop, setPageReady, silent, to]
  );

  const navigateTo = preferFrom && state?.from ? state.from : to;

  const asScrollTo = typeof navigateTo === 'string' && navigateTo.startsWith('#');

  return (
    <>
      {navigateTo ? (
        asScrollTo ? (
          // eslint-disable-next-line react/forbid-elements
          <a href={navigateTo}>{children}</a>
        ) : (
          // eslint-disable-next-line react/jsx-pascal-case
          <_Link
            state={{ from: pathname + search, ...otherProps.state }}
            {...otherProps}
            onClick={handleClick}
            to={navigateTo}
          >
            {children}
          </_Link>
        )
      ) : (
        <span {...otherProps}>{children}</span>
      )}
    </>
  );
};
