import * as yup from 'yup';

import { b2x } from '..';
import { Button } from '../Button';
import { Col } from '../Col';
import { appConfig } from '../config';
import { CustomerFormBContentType } from '../contentTypes';
import { Div } from '../HTMLElement';
import { t } from '../i18n/i18n';
import { Icon } from '../Icon';
import { Row } from '../Row';
import { useContent } from '../useContent';
import { CustomerFormHelper, CustomerFormProps } from './CustomerForm';
import { DateInput, PasswordInput, TextInput } from './fields/Input';
import { Checkbox } from './fields/RadioCheck';
import { FormGroup } from './FormGroup';

// eslint-disable-next-line @typescript-eslint/ban-types
type AdditionalPropertiesFormValues = {};

// eslint-disable-next-line @typescript-eslint/ban-types
type AdditionalPropertiesValidationSchema = yup.ObjectSchema<{}>;

// eslint-disable-next-line @typescript-eslint/ban-types
type CustomPropertiesFormValues = {};

// eslint-disable-next-line @typescript-eslint/ban-types
type CustomPropertiesValidationSchema = yup.ObjectSchema<{}>;

// eslint-disable-next-line @typescript-eslint/ban-types
type AdditionalPropertiesChildrenFormValues = {};

// eslint-disable-next-line @typescript-eslint/ban-types
type AdditionalPropertiesChildrenValidationSchema = yup.ObjectSchema<{}>;

// eslint-disable-next-line @typescript-eslint/ban-types
type CustomPropertiesChildrenFormValues = {};

// eslint-disable-next-line @typescript-eslint/ban-types
type CustomPropertiesChildrenValidationSchema = yup.ObjectSchema<{}>;

export type CustomerFormBProps = Omit<
  CustomerFormProps<
    AdditionalPropertiesFormValues,
    AdditionalPropertiesValidationSchema,
    CustomPropertiesFormValues,
    CustomPropertiesValidationSchema,
    AdditionalPropertiesChildrenFormValues,
    AdditionalPropertiesChildrenValidationSchema,
    CustomPropertiesChildrenFormValues,
    CustomPropertiesChildrenValidationSchema
  >,
  | 'additionalPropertiesInitialValues'
  | 'additionalProperties'
  | 'additionalPropertiesValidationSchema'
  | 'customPropertiesInitialValues'
  | 'customPropertiesValidationSchema'
  | 'validationSchemaSelector'
  | 'additionalPropertiesChildrenInitialValues'
  | 'additionalPropertiesChildren'
  | 'additionalPropertiesChildrenValidationSchema'
  | 'customPropertiesChildrenInitialValues'
  | 'customPropertiesChildrenValidationSchema'
  | 'validationSchemaChildrenSelector'
>;

export const CustomerFormB = ({ enableNewsletterRegistration = true, singleColumn, ...props }: CustomerFormBProps) => {
  const formbContent = useContent<CustomerFormBContentType>('CUSTOMER_FORM_B_CONTENT');
  return (
    <CustomerFormHelper<
      AdditionalPropertiesFormValues,
      AdditionalPropertiesValidationSchema,
      CustomPropertiesFormValues,
      CustomPropertiesValidationSchema,
      AdditionalPropertiesChildrenFormValues,
      AdditionalPropertiesChildrenValidationSchema,
      CustomPropertiesChildrenFormValues,
      CustomPropertiesChildrenValidationSchema
    >
      singleColumn={false}
      {...props}
      className="customer-form-b"
      maxChildren={0}
      maxPhoneNumbers={1}
      minChildren={0}
      minPhoneNumbers={1}
      validationSchemaSelector={{
        birthdate: false,
        children: { birthdate: false, name: false, sex: false, surname: false },
        name: true,
        phoneNumber: false,
        profile: false,
        sex: false,
        surname: true,
      }}
    >
      {({ content, fieldsHelper, formik, mode }) => (
        <>
          <div className="pb-4">
            <Row gap={3}>
              <Col size={singleColumn ? 12 : { lg: 6, xs: 12 }}>
                <FormGroup {...fieldsHelper.name.formGroup} noMarginBottom>
                  <TextInput {...fieldsHelper.name.textInput} placeholder={appConfig.form?.showPlaceholder ? t('form.customerForm.name.placeholder') : undefined} />
                </FormGroup>
              </Col>
              <Col size={singleColumn ? 12 : { lg: 6, xs: 12 }}>
                <FormGroup {...fieldsHelper.surname.formGroup} noMarginBottom>
                  <TextInput {...fieldsHelper.surname.textInput} placeholder={appConfig.form?.showPlaceholder ? t('form.customerForm.surname.placeholder') : undefined} />
                </FormGroup>
              </Col>

              {mode === 'registration' && (
                <>
                  <Col size={singleColumn ? 12 : { lg: 6, xs: 12 }}>
                    <FormGroup {...fieldsHelper.email.formGroup} noMarginBottom>
                      <TextInput {...fieldsHelper.email.textInput} placeholder={appConfig.form?.showPlaceholder ? t('form.customerForm.email.placeholder') : undefined} />
                    </FormGroup>
                  </Col>
                  <Col size={singleColumn ? 12 : { lg: 6, xs: 12 }}>
                    <FormGroup {...fieldsHelper.password.formGroup} noMarginBottom>
                      <PasswordInput {...fieldsHelper.password.passwordInput} placeholder={appConfig.form?.showPlaceholder ? t('form.customerForm.password.placeholder') : undefined} />
                    </FormGroup>
                  </Col>
                </>
              )}
              <Col size={singleColumn ? 12 : { lg: 6, xs: 12 }}>
                <FormGroup {...fieldsHelper.birthdate.formGroup} noMarginBottom>
                  <DateInput {...fieldsHelper.birthdate.dateInput} placeholder={undefined} />
                </FormGroup>
              </Col>
            </Row>
          </div>

          {(mode === 'registration' || mode === 'updateRequiredFields') && (
            <Row className="mb-3">
              {content?.body.newsletter?.enabled && (
                <Col size={12}>
                  <div className="box-newsletter-radio mb-3">
                    <div className="title d-block d-lg-none">
                      {formbContent?.body.icon && (
                        <div className="justify-content-center">
                          <Icon name={formbContent.body.icon} size={16} />
                        </div>
                      )}
                      {formbContent?.body.title}
                    </div>
                    <div className="title d-none d-lg-block">
                      {formbContent?.body.icon && <Icon className="me-2" name={formbContent.body.icon} size={16} />}
                      {b2x.formatHtml(formbContent?.body.title)}
                    </div>
                    <div className="body pb-2">{b2x.formatHtml(formbContent?.body.body)}</div>
                    {/* <div className="radio">
                      <FormGroup {...fieldsHelper.newsletterConsent.formGroup}>
                        {fieldsHelper.newsletterConsent.radios.radios.map((radio) => (
                          // eslint-disable-next-line react/jsx-key
                          <Radio
                            {...radio}
                            label={
                              radio.value === 'true'
                                ? formbContent.body.label?.yesLabel
                                : formbContent.body.label?.notLabel
                            }
                          />
                        ))}
                      </FormGroup>
                    </div> */}
                    <FormGroup {...fieldsHelper.newsletterConsent.formGroup}>
                      <Checkbox
                        {...fieldsHelper.newsletterConsent.checkbox}
                        inline
                        // label={content.body.newsletter.label}
                        labelClassName="extra-small"
                      />
                    </FormGroup>
                  </div>
                </Col>
              )}
              {/* {content?.body.newsletter?.enabled && (
                <Col size={12}>
                  <FormGroup {...fieldsHelper.newsletterConsent.formGroup} >
                    <Checkbox labelClassName="extra-small" {...fieldsHelper.newsletterConsent.checkbox} />
                  </FormGroup>
                </Col>
              )} */}
              {content?.body.profiling?.enabled && (
                <Col size={12}>
                  <FormGroup {...fieldsHelper.profilingConsent.formGroup}>
                    <Checkbox labelClassName="extra-small" {...fieldsHelper.profilingConsent.checkbox} />
                  </FormGroup>
                </Col>
              )}
              {content?.body.marketing?.enabled && (
                <Col size={12}>
                  <FormGroup {...fieldsHelper.marketingConsent.formGroup}>
                    <Checkbox labelClassName="extra-small" {...fieldsHelper.marketingConsent.checkbox} />
                  </FormGroup>
                </Col>
              )}
              <Col size={12}>
                <FormGroup {...fieldsHelper.privacyConsent.formGroup}>
                  <Checkbox labelClassName="extra-small" {...fieldsHelper.privacyConsent.checkbox} />
                </FormGroup>
              </Col>
            </Row>
          )}
          {fieldsHelper.buttons.cancel ? (
            <Row>
              <Col size={6}>
                <Div display={'grid'}>
                  <Button className="cancel-button" variant="outline-secondary" {...fieldsHelper.buttons.cancel} />
                </Div>
              </Col>
              <Col size={6}>
                <Div display={'grid'}>
                  <Button className="submit-button" {...fieldsHelper.buttons.submit} />
                </Div>
              </Col>
            </Row>
          ) : (
            <Div>
              {Object.keys(formik.errors).length > 0 && (
                <p className="small text-danger">{t('misc.invalid_form_values')}</p>
              )}
              <Div textAlign={'center'}>
                <Button {...fieldsHelper.buttons.submit} />
              </Div>
            </Div>
          )}
        </>
      )}
    </CustomerFormHelper>
  );
};
