import { Button } from '../Button';
import { Col } from '../Col';
import { Div } from '../HTMLElement';
import { t } from '../i18n/i18n';
import { Row } from '../Row';
import { uiClassName } from '../util';
import { Select } from './fields/Select';
import { FormGroup } from './FormGroup';
import { InputGroup } from './InputGroup';
import { LocaleFormHelper, LocaleFormProps } from './LocaleForm';

export const LocaleFormC = ({ singleColumn, ...otherProps }: LocaleFormProps) => (
  <LocaleFormHelper className="locale-form-c" {...otherProps} singleColumn={singleColumn}>
    {({ fieldsHelper }) => (
      <>
        <Div className={'title-container'} marginBottom={4} textAlign="center">
          <span className="title">{t('misc.locale.box.title.country')}</span>
        </Div>
        <Row>
          <Col size={12}>
            <Div marginBottom={!singleColumn ? 4 : undefined}>
              <FormGroup {...fieldsHelper.locale.formGroup} label={undefined}>
                <InputGroup names={[fieldsHelper.locale.select.name]}>
                  <Select {...fieldsHelper.locale.select} className={uiClassName({ bs5: 'text-capitalize' })} />
                  <Button {...fieldsHelper.buttons.submit} />
                </InputGroup>
              </FormGroup>
            </Div>
          </Col>
        </Row>
      </>
    )}
  </LocaleFormHelper>
);
