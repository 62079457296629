import { baim as _baim } from '@b2x/baim/src';

import { appConfig } from '../config';
// import { cookieConsents } from '../cookieConsents';
import { environment, slugify } from '../util';
import { AnalyticsEventsProps, InitCommonProps } from './analytics';
import {
  Event,
  EventParameters,
  fromProductSkusToItems,
  fromProductSkuToItem,
  fromProductSkuWithQuantityToItem,
  fromProductsSkusToItems,
} from './googleAnalytics4';

let initialized = false;
let debug = false;

interface InitProps extends InitCommonProps {}

export type BaimInitProps = InitProps;

const init = (props?: InitProps): void => {
  debug = props?.debug || false;
  if (!initialized) {
    initialized = true;
    _baim.init();
  }
};
const sendEvent = (eventId: string, event: Event, parameters?: EventParameters) => {
  if (environment === 'TEST') {
    if (initialized) {
      _baim.sendEvent({
        // cookieConsentsData: {
        //   consents: {
        //     marketing: cookieConsents.get()?.consents.marketing,
        //     necessary: cookieConsents.get()?.consents.necessary,
        //     preferences: cookieConsents.get()?.consents.preferences,
        //     statistics: cookieConsents.get()?.consents.statistics,
        //   },
        //   provider:
        //     cookieConsents.get()?.provider === 'Civic'
        //       ? 'CIVIC'
        //       : cookieConsents.get()?.provider === 'Cookiebot'
        //       ? 'COOKIEBOT'
        //       : undefined,
        //   regulations: {
        //     ccpa: cookieConsents.get()?.regulations.ccpa,
        //     gdpr: cookieConsents.get()?.regulations.gdpr,
        //     lgpd: cookieConsents.get()?.regulations.lgpd,
        //   },
        // },
        event,
        eventId,
        parameters,
        storeId: appConfig.storeId,
        success: true,
      });
      log(event, eventId, parameters);
    } else {
      log('Blocked because not initialized.', event, parameters);
    }
  } else {
    log('Blocked because not test environment.', event, parameters);
  }
};

const log = (message?: unknown, ...optionalParams: Array<unknown>) => {
  if (debug) {
    console.log('analytics', 'baim:', message, ...optionalParams);
  }
};

export const baim: {
  events: AnalyticsEventsProps;
  init(props?: InitProps): void;
} = {
  events: {
    addPaymentInfo: (props) => {
      sendEvent(props.eventId, 'add_payment_info', {
        coupon: props.coupon?.code,
        currency: 'EUR',
        items: props.products.map((product) => fromProductSkuWithQuantityToItem(product)),
        payment_type: props.paymentType,
        value: props.totalCost,
      });
    },
    addShippingInfo: (props) => {
      sendEvent(props.eventId, 'add_shipping_info', {
        coupon: props.coupon?.code,
        currency: 'EUR',
        items: props.products.map((product) => fromProductSkuWithQuantityToItem(product)),
        shipping_tier: props.shippingProfile,
        value: props.totalCost,
      });
    },
    addToCart: (props) => {
      sendEvent(props.eventId, 'add_to_cart', {
        currency: 'EUR',
        items: props.products.map((product) => fromProductSkuWithQuantityToItem(product)),
        value: props.totalPrice.value,
      });
    },
    addToWishlist: (props) => {
      sendEvent(props.eventId, 'add_to_wishlist', {
        currency: 'EUR',
        items: [fromProductSkuToItem(props.product)],
        value: props.product.sku.price.value,
      });
    },
    checkoutInit: (props) => {
      sendEvent(props.eventId, 'begin_checkout', {
        coupon: props.coupon?.code,
        currency: 'EUR',
        items: props.products.map((product) => fromProductSkuWithQuantityToItem(product)),
        value: props.totalCost,
      });
    },
    completeRegistration: (props) => {
      sendEvent(props.eventId, 'sign_up', { method: props.origin });
    },
    contact: (props) => {
      // Assente in baim
    },
    customizeProduct: (props) => {
      // Assente in baim
    },
    donate: (props) => {
      // Assente in baim
    },
    exception: (props) => {
      // Assente in baim
    },
    findLocation: (props) => {
      // Assente in baim
    },
    lead: (props) => {
      sendEvent(props.eventId, 'generate_lead', {
        // currency: '',
        // value: 0,
      });
    },
    login: (props) => {
      sendEvent(props.eventId, 'login', {
        method: props.method,
      });
    },
    pageLeave: (props) => {
      // Assente in baim
    },
    pageView: (props) => {
      // Assente in baim
    },
    purchase: (props) => {
      sendEvent(props.eventId, 'purchase', {
        coupon: props.coupon?.code,
        currency: 'EUR',
        items: props.products.map((product) => fromProductSkuWithQuantityToItem(product)),
        shipping: props.shippingCost,
        tax: props.taxCost,
        transaction_id: props.orderCode,
        value: props.totalCost,
      });
    },
    removeFromCart: (props) => {
      sendEvent(props.eventId, 'remove_from_cart', {
        currency: 'EUR',
        items: [fromProductSkuWithQuantityToItem(props.product)],
        value: props.product.sku.price.value,
      });
    },
    schedule: () => {
      // Assente in baim
    },
    search: (props) => {
      sendEvent(props.eventId, 'search', {
        search_term: props.simpleSearch,
      });
    },
    selectProduct: (props) => {
      sendEvent(props.eventId, 'select_item', {
        item_list_id: slugify(props.listing.name),
        item_list_name: props.listing.name,
        items: fromProductSkusToItems(props.product),
      });
    },
    startPayment: (props) => {
      // Assente in baim
    },
    submitApplication: () => {
      // Assente in baim
    },
    viewCart: (props) => {
      sendEvent(props.eventId, 'view_cart', {
        currency: 'EUR',
        items: props.products.map((product) => fromProductSkuWithQuantityToItem(product)),
        value: props.totalCost,
      });
    },
    viewProduct: (props) => {
      sendEvent(props.eventId, 'view_item', {
        currency: 'EUR',
        items: fromProductSkusToItems(props.product),
        value: props.product.fromPrice.value,
      });
    },
    viewProducts: (props) => {
      sendEvent(props.eventId, 'view_item_list', {
        items: fromProductsSkusToItems(props.products),
        // item_list_id: '',
        // item_list_name: '',
      });
    },
  },
  init: (props) => {
    init(props);
  },
};
