import { AttributeApiDto, ImageApiDto, ProductApiDto, SkuApiDto } from '@b2x/storefront-api-js-client/src/dto';

import { ButtonProps } from './Button';
import { AddToCartFieldsHelper, AddToCartFormValues } from './form/AddToCartForm';
import { FormikProps } from './form/Form';
import { ProductStickyFooterA } from './ProductStickyFooterA';
import { ProductStickyFooterB } from './ProductStickyFooterB';
import { ProductStickyFooterC } from './ProductStickyFooterC';
import { ProductStickyFooterD } from './ProductStickyFooterD';
import { UsePriceResult } from './usePrice';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface ProductStickyFooterProps<ButtonVariant, IconName, IconSize> {
  className?: string;
  fieldsHelper: AddToCartFieldsHelper;
  formik: FormikProps<AddToCartFormValues>;
  isVisible?: boolean;
  offsetBottom?: number;
  offsetTop?: number;
  priceHelper: UsePriceResult;
  product: ProductApiDto;
  scrollToElement?(): void;
  selectedProductAttributes?: Array<AttributeApiDto>;
  selectedProductImage?: ImageApiDto;
  selectedSku: SkuApiDto | undefined;
  selectedVariant?: ProductApiDto;
  submitDesktopButton?: ButtonProps<ButtonVariant, IconName, IconSize>;
  submitMobileButton?: ButtonProps<ButtonVariant, IconName, IconSize>;
}

export type ProductStickyFooterVariants = 'A' | 'B' | 'C' | 'D';

const ProductStickyFooterController = <ButtonVariant extends string, IconName extends string, IconSize extends number>(
  props: PropsWithCustomComponent<ProductStickyFooterProps<ButtonVariant, IconName, IconSize>>
) => (
  <VariantsController<ProductStickyFooterProps<ButtonVariant, IconName, IconSize>, ProductStickyFooterVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: {
        A: ProductStickyFooterA,
        B: ProductStickyFooterB,
        C: ProductStickyFooterC,
        D: ProductStickyFooterD,
      },
      name: 'ProductStickyFooter',
    }}
  />
);
export { ProductStickyFooterController as ProductStickyFooter };
