import { FreeShippingThresholdContentType } from '../contentTypes';
import { PropsWithCustomComponent, VariantsController } from '../VariantsController';
import { CartPageA } from './CartPageA';
import { CartPageB } from './CartPageB';

export interface CartPageProps extends FreeShippingThresholdContentType {
  cartSkusToMoveCampaignsCodes?: Array<string>;
  showCampaignOfferOnMount?: boolean;
  summaryBottomInfo?: React.ReactElement;
  summaryTopInfo?: React.ReactElement;
}

export type CartPageVariants = 'A' | 'B';

const CartPageController = (props: PropsWithCustomComponent<CartPageProps>) => (
  <VariantsController<CartPageProps, CartPageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: CartPageA, B: CartPageB },
      name: 'CartPage',
    }}
  />
);
export { CartPageController as CartPage };
