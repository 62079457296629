import React from 'react';

import { useWindowSize } from './useWindowSize';

export interface ParentCovererProps {
  childHeight: number;
  childWidth: number;
  children?: React.ReactNode;
}

/*
  Componente che simula la proprietà css "object-fit: cover" (applicabile solo a <img /> e <video />)
  ma applicabile a un qualsiasi elemento di tipo blocco, passato come children.
*/
export const ParentCoverer = ({ childHeight, childWidth, children }: ParentCovererProps) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const windowSize = useWindowSize();

  const [widthPercentageToCoverParentHeight, setWidthPercentageToCoverParentHeight] = React.useState<number>(100);

  const updateWidthPercentageToCoverParentHeight = React.useCallback(() => {
    const width = ref.current?.clientWidth ?? 0;
    const height = ref.current?.clientHeight ?? 0;
    const parentAspectRatio = width / height;
    const childAspectRatio = childWidth / childHeight;
    // console.log({
    //   aspectRatio,
    //   height: height,
    //   parentAspectRatio,
    //   width: width,
    // });
    setWidthPercentageToCoverParentHeight(
      parentAspectRatio < childAspectRatio ? (childAspectRatio / parentAspectRatio) * 100 : 100
    );
  }, [childHeight, childWidth]);

  React.useEffect(() => {
    updateWidthPercentageToCoverParentHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  React.useEffect(() => {
    window.setTimeout(() => {
      updateWidthPercentageToCoverParentHeight();
    }, 1000);
  }, [updateWidthPercentageToCoverParentHeight]);

  return (
    <div ref={ref} style={{ bottom: 0, left: 0, overflow: 'hidden', position: 'absolute', right: 0, top: 0 }}>
      <div
        className="parent-coverer"
        style={{
          left: '50%',
          position: 'relative',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: `${widthPercentageToCoverParentHeight}%`,
        }}
      >
        {children}
      </div>
    </div>
  );
};
