import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { useCustomerApi } from '../api/useCustomerApi';
import { Button } from '../Button';
import { appConfig } from '../config';
import { t } from '../i18n/i18n';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { useModals } from '../useModals';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { TextInput, TextInputProps } from './fields/Input';
import { FormButtonProps, formikString, getInitialString, isResetButtonDisabled, isSubmitButtonDisabled } from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';

export interface ForgotPasswordFormProps
  extends BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector> {}

interface FormValues {
  email: formikString;
}

type ValidationSchema = {
  email: yup.StringSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  email: { formGroup: FormGroupProps; textInput: TextInputProps };
}

export const ForgotPasswordFormHelper = ({
  children,
  className,
  initialValues,
  onCancel,
  onSuccess,
  ...otherProps
}: ForgotPasswordFormProps) => {
  const { showModal } = useModals();

  const { forgotPassword } = useCustomerApi();

  const _initialValues = React.useMemo<FormValues>(
    () => ({
      email: getInitialString(),
      ...initialValues,
    }),
    [initialValues]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      email: yup.string().required(),
    }),
    []
  );

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) =>
      forgotPassword(values).then(() => {
        onSuccess
          ? onSuccess()
          : showModal({
              children: t('form.forgotPasswordForm.outcome.success.body'),
              title: t('form.forgotPasswordForm.outcome.success.title'),
            });
      }),
    [forgotPassword, onSuccess, showModal]
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  return (
    <HelpedForm<FormValues>
      className={classnames('ForgotPasswordForm', className)}
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          buttons: {
            cancel: onCancel
              ? {
                  label: t('form.loginForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: t('form.forgotPasswordForm.buttons.reset.label'),
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label: t('form.forgotPasswordForm.buttons.submit.label'),
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          email: {
            formGroup: { label: t('form.forgotPasswordForm.fields.email.label'), names: ['email'] },
            textInput: {
              name: 'email',
              placeholder: t('form.forgotPasswordForm.fields.email.placeholder'),
              type: 'email',
            },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal })
        ) : (
          <>
            <FormGroup {...fieldsHelper.email.formGroup}>
              <TextInput {...fieldsHelper.email.textInput} placeholder={appConfig.form?.showPlaceholder ? t('form.forgotPasswordForm.fields.email.placeholder') : undefined} />
            </FormGroup>
            <Button {...fieldsHelper.buttons.submit} />
          </>
        );
      }}
    </HelpedForm>
  );
};

export type ForgotPasswordFormVariants = '';

const ForgotPasswordFormController = (props: PropsWithCustomComponentWithoutChildren<ForgotPasswordFormProps>) => (
  <VariantsController<ForgotPasswordFormProps, ForgotPasswordFormVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: ForgotPasswordFormHelper,
      name: 'ForgotPasswordForm',
    }}
  />
);
export { ForgotPasswordFormController as ForgotPasswordForm };
