import classNames from 'classnames';
import React from 'react';

import { Button } from './Button';
import { ResponsiveJustifyContent } from './interfaces';
import { buildJustifyContentClassNames, renderUI, slugify } from './util';

export interface TabsProps {
  className?: string;
  contentClassName?: string;
  direction?: 'horizontal' | 'vertical';
  fill?: boolean;
  horizontalAlignment?: ResponsiveJustifyContent;
  id?: string;
  initialActiveTab?: number;
  tabStyle?: 'tabs' | 'pills';
  tabs: Array<{
    children: React.ReactNode;
    customTitle?: React.ReactNode;
    title: string;
  }>;
}

export const Tabs = ({
  className,
  contentClassName,
  direction = 'horizontal',
  fill,
  horizontalAlignment = 'start',
  id,
  initialActiveTab = 0,
  tabStyle,
  tabs,
}: TabsProps) => {
  const [activeIndex, setActiveIndex] = React.useState<number>(initialActiveTab);

  return renderUI({
    bs5: (
      <>
        <ul
          className={classNames(
            'nav',
            { [`nav-${tabStyle}`]: tabStyle !== undefined },
            buildJustifyContentClassNames(horizontalAlignment),
            { 'flex-column': direction === 'vertical' },
            { 'nav-fill': fill },
            className
          )}
          role="tablist"
        >
          {tabs.map(({ customTitle, title }, index) => (
            <li className="nav-item" key={title} role="presentation">
              {customTitle ? (
                <Button
                  aria-controls={slugify(title)}
                  aria-selected={index === 0 ? 'true' : 'false'}
                  className={classNames({ active: activeIndex === index })}
                  data-bs-target={`#${slugify(title)}`}
                  data-bs-toggle="tab"
                  id={slugify(`${title}-tab`)}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => setActiveIndex(index)}
                  role="tab"
                  style={{ all: 'unset' }}
                >
                  {customTitle}
                </Button>
              ) : (
                /* eslint-disable-next-line react/forbid-elements */
                <button
                  aria-controls={slugify(title)}
                  aria-selected={index === 0 ? 'true' : 'false'}
                  className={classNames('nav-link', { active: activeIndex === index })}
                  data-bs-target={`#${slugify(title)}`}
                  data-bs-toggle="tab"
                  id={slugify(`${title}-tab`)}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => setActiveIndex(index)}
                  role="tab"
                  type="button"
                >
                  {title}
                </button>
              )}
            </li>
          ))}
        </ul>

        <div className={classNames('tab-content', contentClassName)} id={id}>
          {tabs.map(({ children, title }, index) => (
            <div
              aria-labelledby={slugify(`${title}-tab`)}
              className={classNames('tab-pane fade', { 'show active': activeIndex === index })}
              id={slugify(title)}
              key={title}
              role="tabpanel"
            >
              {activeIndex === index && children}
            </div>
          ))}
        </div>
      </>
    ),
  });
};
