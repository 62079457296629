import {
  CartSkuApiDto,
  OfferApiDto,
  OfferCampaignApiDto,
  ProductApiDto,
  SkuApiDto,
} from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

import { useCartApi } from './api/useCartApi';

export interface UseCartCampaignSingleOfferBuyXGetYFlattenedSkusProps {
  campaign: OfferCampaignApiDto;
  campaignsCartSkus: Partial<Record<string, Array<CartSkuApiDto>>> | undefined;
}

export interface UseCartCampaignSingleOfferBuyXGetYFlattenedSkusResult {
  addToCartButtonEnabled: boolean;
  campaignCartSkus: Array<CartSkuApiDto> | undefined;
  handleAddToCartButtonClick(): Promise<void>;
  handlePickerSkuButtonClick(skuId: string): void;
  isLoadingSelectedSkuIds: boolean;
  offer: OfferApiDto;
  pickerSkus: Array<PickerSku> | undefined;
  selectedSkusIds: Array<string>;
}

interface PickerSku extends Omit<SkuApiDto, 'product'> {
  buttonEnabled: boolean;
  product: ProductApiDto;
  selected: boolean;
}

export const useCartCampaignSingleOfferBuyXGetYFlattenedSkus = ({
  campaign,
  campaignsCartSkus,
}: UseCartCampaignSingleOfferBuyXGetYFlattenedSkusProps): UseCartCampaignSingleOfferBuyXGetYFlattenedSkusResult => {
  const [selectedSkusIds, setSelectedskusIds] = React.useState<Array<string>>([]);
  const [isLoadingSelectedSkuIds, setIsLoadingSelectedSkuIds] = React.useState<boolean>(true);

  const campaignName = React.useMemo<string>(() => {
    if (campaign.name === undefined) {
      throw new Error('CartCampaignWithoutName');
    }
    return campaign.name;
  }, [campaign.name]);

  const campaignCartSkus = React.useMemo(
    () => (campaignsCartSkus ? campaignsCartSkus[campaignName] : undefined),
    [campaignName, campaignsCartSkus]
  );

  React.useEffect(() => {
    const _selectedSkusIds: Array<string> = [];
    campaignCartSkus?.forEach((campaignCartSku) => {
      if (campaignCartSku.sku?.id !== undefined) {
        _selectedSkusIds.push(campaignCartSku.sku.id);
      }
    });
    setSelectedskusIds(_selectedSkusIds);
    setIsLoadingSelectedSkuIds(false);
  }, [campaignCartSkus]);

  const offer = React.useMemo<OfferApiDto>(() => {
    const _offer = campaign.offers?.at(0);
    if (_offer === undefined) {
      throw new Error('UseCartCampaignSingleOffer, but without any offer');
    }
    return _offer;
  }, [campaign.offers]);

  const [products, setProducts] = React.useState<Array<ProductApiDto>>();

  const { getOfferYProducts } = useCartApi();

  React.useEffect(() => {
    getOfferYProducts(offer.id, { populate: { skus: { price: true } } }).then((response) => {
      setProducts(response.data);
    });
  }, [getOfferYProducts, offer.id]);

  const pickerSkus = React.useMemo<Array<PickerSku> | undefined>(() => {
    let skus: Array<PickerSku> | undefined = undefined;
    if (products) {
      skus = [];
      products.forEach((product) => {
        product.skus?.forEach((sku) => {
          if (sku.state === 'AVAILABLE') {
            skus?.push({
              ...sku,
              buttonEnabled:
                offer.yQuantity === undefined ||
                selectedSkusIds.includes(sku.id) ||
                selectedSkusIds.length < offer.yQuantity,
              product: { ...product, skus: undefined },
              selected: selectedSkusIds.includes(sku.id),
            });
          }
        });
      });
    }
    return skus;
  }, [offer.yQuantity, products, selectedSkusIds]);

  const selectedSkus = React.useMemo(
    () => pickerSkus?.filter((pickerSku) => selectedSkusIds.includes(pickerSku.id)),
    [pickerSkus, selectedSkusIds]
  );

  const handlePickerSkuButtonClick = React.useCallback((skuId: string) => {
    setSelectedskusIds((prevState) => {
      if (prevState.includes(skuId)) {
        return prevState.filter((_skuId) => _skuId !== skuId);
      } else {
        return prevState.concat(skuId);
      }
    });
  }, []);

  const addToCartButtonEnabled = React.useMemo(() => selectedSkusIds.length > 0, [selectedSkusIds.length]);

  const { addSkus } = useCartApi();

  const handleAddToCartButtonClick = React.useCallback(
    () =>
      new Promise<void>((resolve, reject) => {
        if (selectedSkus) {
          addSkus(
            selectedSkus.map((selectedSku) => ({
              offerId: offer.id,
              product: selectedSku.product,
              quantity: 1,
              sku: selectedSku,
            }))
          ).then(() => {
            // Per ora non chiamo analytics, manderei i prezzi del campionario, quando invece i prezzi sono soggetti allo sconto su Y.
            // analytics.events.addToCart(
            //   response.eventId,
            //   selectedSkus.map((selectedSku) => ({
            //     partialSku: selectedSku,
            //     quantity: 1,
            //   }))
            // );
            resolve();
          });
        } else {
          reject();
        }
      }),
    [addSkus, offer.id, selectedSkus]
  );

  return {
    addToCartButtonEnabled,
    campaignCartSkus,
    handleAddToCartButtonClick,
    handlePickerSkuButtonClick,
    isLoadingSelectedSkuIds,
    offer,
    pickerSkus,
    selectedSkusIds,
  };
};
