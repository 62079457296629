import { MenuApiDto } from '@b2x/storefront-api-js-client/src';
import { GetMenuOptions } from '@b2x/storefront-api-js-client/src/menus';
import React from 'react';

import { ApiRequestConfig } from './api/useApiRequest';
import { useMenusApi } from './api/useMenusApi';
import { appConfig } from './config';
import { storage } from './storage';
import { deepFilterMenu, useStable } from './util';

export interface UseMenuOptions extends GetMenuOptions {
  deepFilter?(menu: MenuApiDto): boolean;
}

export const useMenu = (code: string, options?: UseMenuOptions, config?: ApiRequestConfig) => {
  const [menu, setMenu] = React.useState<MenuApiDto | undefined>(storage.getObject<MenuApiDto>(code));

  const { getMenuByCode } = useMenusApi();

  options = useStable(options);
  config = useStable(config);

  React.useEffect(() => {
    if (!menu) {
      getMenuByCode(code, options, config)
        .then((response) => {
          const result = options?.deepFilter ? deepFilterMenu(response.data, options.deepFilter) : response.data;
          // const result = response.data;
          setMenu(result);
          if (appConfig.saveResourcesInSession) {
            storage.setObject(code, result);
          }
        })
        .catch(() => {
          // pazienza
        });
    }
  }, [code, config, getMenuByCode, menu, options]);

  return menu;
};
