import { CartSkuApiDto } from '@b2x/storefront-api-js-client/src/dto';
import classnames from 'classnames';
import React from 'react';
import * as yup from 'yup';

import { useCartApi } from '../api/useCartApi';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { Select, SelectProps } from './fields/Select';
import { formikString, getInitialString } from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';

export interface CartSkuQuantityFormProps
  extends BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector> {
  availability?: number;
  cartSku: CartSkuApiDto;
  readonly?: boolean;
}

interface FormValues {
  quantity: formikString;
}

type ValidationSchema = {
  quantity: yup.StringSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  quantity: { formGroup: FormGroupProps; select: SelectProps };
}

export const CartSkuQuantityFormHelper = ({
  availability,
  cartSku,
  children,
  className,
  initialValues,
  onCancel,
  onSuccess,
  readonly,
  ...otherProps
}: CartSkuQuantityFormProps) => {
  const _initialValues = React.useMemo<FormValues>(
    () => ({
      quantity: getInitialString(cartSku.quantity.toString()),
      ...initialValues,
    }),
    [cartSku.quantity, initialValues]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      quantity: yup.string().required(),
    }),
    []
  );

  const { changeSkuQuantity } = useCartApi();

  const handleSubmit = React.useCallback(
    (values: FormValues) =>
      changeSkuQuantity({ id: cartSku.id, quantity: parseInt(values.quantity) }).then(() => {
        onSuccess && onSuccess();
        // FIX ME - ALESSANDRO
        // Lavorare qui
      }),
    [cartSku.id, changeSkuQuantity, onSuccess]
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  return (
    <HelpedForm<FormValues>
      className={classnames('CartSkuQuantityForm', className)}
      closeModalOnSuccess={false}
      enableReinitialize
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      submitOnChange={!readonly && cartSku.canEditQuantity}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          quantity: {
            formGroup: { label: 'Quantity', names: ['quantity'] },
            select: {
              disabled: readonly || !cartSku.canEditQuantity,
              includeEmptyOption: false,
              name: 'quantity',
              options: [...Array(availability)].map((x, index) => ({
                label: (index + 1).toString(),
                value: (index + 1).toString(),
              })),
            },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal })
        ) : (
          <FormGroup {...fieldsHelper.quantity.formGroup}>
            <Select {...fieldsHelper.quantity.select} />
          </FormGroup>
        );
      }}
    </HelpedForm>
  );
};

export type CartSkuQuantityFormVariants = '';

const CartSkuQuantityFormController = (props: PropsWithCustomComponentWithoutChildren<CartSkuQuantityFormProps>) => (
  <VariantsController<CartSkuQuantityFormProps, CartSkuQuantityFormVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: CartSkuQuantityFormHelper,
      name: 'CartSkuQuantityForm',
    }}
  />
);
export { CartSkuQuantityFormController as CartSkuQuantityForm };
