import { useAppContext } from '../AppContext';
import { appConfig } from '../config';
import { t } from '../i18n/i18n';
import { AddressFieldsetProps } from './AddressFieldset';
import { DynamicField } from './fields/DynamicField';
import { TextInput } from './fields/Input';
import { Select } from './fields/Select';
import { FormGroup } from './FormGroup';
import { AddressValidationSchemaSelector } from './useAddressForm';

export const AddressFieldsetA = ({ addressFieldsHelper }: AddressFieldsetProps) => {
  const { info, shippingCountry } = useAppContext();
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-4 col-lg-4">
          <FormGroup {...addressFieldsHelper.name.formGroup}>
            <TextInput {...addressFieldsHelper.name.textInput} placeholder={appConfig.form?.showPlaceholder ? t('form.addressForm.address.name.placeholder') : undefined}/>
          </FormGroup>
        </div>
        <div className="col-12 col-md-4 col-lg-4">
          <FormGroup {...addressFieldsHelper.surname.formGroup}>
            <TextInput {...addressFieldsHelper.surname.textInput} placeholder={appConfig.form?.showPlaceholder ? t('form.addressForm.address.surname.placeholder') : undefined}/>
          </FormGroup>
        </div>
        <div className="col-12 col-md-4 col-lg-4">
          <FormGroup {...addressFieldsHelper.phone.formGroup}>
            <TextInput {...addressFieldsHelper.phone.textInput} placeholder={appConfig.form?.showPlaceholder ? t('form.addressForm.address.phone.placeholder') : undefined}/>
          </FormGroup>
        </div>
        <div className="col-12 col-md-6">
          <FormGroup {...addressFieldsHelper.addressLine1.formGroup}>
            <TextInput {...addressFieldsHelper.addressLine1.textInput} placeholder={appConfig.form?.showPlaceholder ? t('form.addressForm.address.addressLine1.placeholder') : undefined}/>
          </FormGroup>
        </div>
        <div className="col-12 col-md-6 col-lg-2">
          <FormGroup {...addressFieldsHelper.civicNumber.formGroup}>
            <TextInput {...addressFieldsHelper.civicNumber.textInput} placeholder={appConfig.form?.showPlaceholder ? t('form.addressForm.address.civicNumber.placeholder') : undefined}/>
          </FormGroup>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <FormGroup {...addressFieldsHelper.country.formGroup}>
            <Select {...addressFieldsHelper.country.select} />
            {info?.shippingCountries &&
              info.shippingCountries.length > 1 &&
              addressFieldsHelper.country.select.disabled &&
              addressFieldsHelper.country.select.options && (
                <div className="extra-small lh-1 mt-2">
                  {t('form.addressForm.address.shippingToAlert', {
                    locale: shippingCountry?.name,
                  })}
                </div>
              )}
          </FormGroup>
        </div>
        <div className="col-12 col-md-6">
          <FormGroup {...addressFieldsHelper.city.formGroup}>
            <TextInput {...addressFieldsHelper.city.textInput} placeholder={appConfig.form?.showPlaceholder ? t('form.addressForm.address.city.placeholder') : undefined}/>
          </FormGroup>
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          {addressFieldsHelper.province && (
            <FormGroup {...addressFieldsHelper.province.formGroup}>
              <Select {...addressFieldsHelper.province.select} placeholder={appConfig.form?.showPlaceholder ? t('form.addressForm.address.province.placeholder') : undefined}/>
            </FormGroup>
          )}
          {addressFieldsHelper.region && (
            <FormGroup {...addressFieldsHelper.region.formGroup}>
              <TextInput {...addressFieldsHelper.region.textInput} />
            </FormGroup>
          )}
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <FormGroup {...addressFieldsHelper.zipCode.formGroup}>
            <DynamicField {...addressFieldsHelper.zipCode.dynamicField}/>
          </FormGroup>
        </div>
        <div className="col-12">
          <FormGroup {...addressFieldsHelper.addressLine2.formGroup}>
            <TextInput {...addressFieldsHelper.addressLine2.textInput} placeholder={appConfig.form?.showPlaceholder ? t('form.addressForm.address.addressLine2.placeholder') : undefined}/>
          </FormGroup>
        </div>
      </div>
    </>
  );
};

export const addressValidationSchemaSelector: AddressValidationSchemaSelector = {
  addressLine2: false,
  addressLine3: false,
  alternativePhone: false,
  latitude: false,
  longitude: false,
  middleName: false,
  phone: true,
  region: false,
};
