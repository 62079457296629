import { Button } from './Button';
import { appConfig } from './config';
import { Container } from './Container';
import { HeaderCheckoutProps } from './HeaderCheckout';
import { Div } from './HTMLElement';
import { t } from './i18n/i18n';
import { Image } from './Image';
import { Link } from './router/Link';

export const HeaderCheckoutB = ({ logo, logoSmall, recalculateHeaderHeight, wrapperStyle }: HeaderCheckoutProps) => {
  return (
    <>
      <Div className={'header-checkout'}>
        <Div className="first-line" paddingY={3} style={{ position: 'relative' }}>
          <Container>
            <Div textAlign={'center'}>
              <Link to="/">
                <Image className="logo" fluid onLoad={recalculateHeaderHeight} src={logo} />
              </Link>
            </Div>
          </Container>
        </Div>
        <Div className="second-line" paddingY={3}>
          <Container>
            <Div className="backlink-container">
              <Link to="/cart">
                <Button
                  iconStart={appConfig.icons?.arrow?.left ? { name: appConfig.icons.arrow.left } : undefined}
                  label={t('checkout.backToCart')}
                />
              </Link>
            </Div>
          </Container>
        </Div>
      </Div>
    </>
  );
};
