import { PageApiDto } from '@b2x/storefront-api-js-client/src';
import { GetPageOptions } from '@b2x/storefront-api-js-client/src/pages';
import React from 'react';

import { ApiRequestConfig } from './api/useApiRequest';
import { usePagesApi } from './api/usePagesApi';
import { useLocation } from './router/router';
import { useStable } from './util';

export interface UsePageOptions extends GetPageOptions {
  secondStep?: GetPageOptions;
}

export const usePage = <T,>(options?: UsePageOptions, config?: ApiRequestConfig) => {
  // Spostare questa variabile di stato su App, insieme a una nuova fetchingPage, e spostare su questo nuovo booleano il setPageReady
  const [page, setPage] = React.useState<PageApiDto<T>>();

  const { getPageByPath } = usePagesApi();

  const location = useLocation();
  let { populate, ...otherOptions } = options ?? {};

  options = useStable(options);
  config = useStable(config);
  populate = useStable(
    typeof populate === 'object' || populate === undefined ? { content: true, ...populate } : populate
  );
  otherOptions = useStable(otherOptions);

  React.useEffect(() => {
    /*
      Se passo da una prima pagina ad una seconda, rendereizzata dalla stesso componente della prima, la variabiile "page" rimane sporca fino alla seguente set.
      La resetto prima di prendere la nuova, per mostrare ad esempio un loading condizionato dalla presenza di page.
    */
    setPage(undefined);
    getPageByPath<T>(
      location.pathname,
      {
        populate: populate,
        ...otherOptions,
      },
      { suppressErrorModal: true, ...config }
    )
      .then((response) => {
        setPage(response.data);
        if (options?.secondStep) {
          getPageByPath<T>(location.pathname, options.secondStep, { silent: true }).then((secondStepResponse) => {
            setPage((prevState) => (prevState ? { ...prevState, ...secondStepResponse.data } : undefined));
          });
        }
      })
      .catch(() => {
        // pazienza
      });
  }, [config, getPageByPath, location.pathname, options?.secondStep, otherOptions, populate]);

  return page;
};
