import { PurinaAssignQualifierRequestApiDto, PurinaAssignQualifierResponseApiDto } from './dto';
import { apiRequest, BaseOptions, getBaseOptions } from './utils/util';

const rootPath = '/v2/custom';

export const purinaAssignQualifier = async (data: PurinaAssignQualifierRequestApiDto, options?: BaseOptions) =>
  apiRequest<PurinaAssignQualifierResponseApiDto, PurinaAssignQualifierRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'POST',
    url: `${rootPath}/purina/assignQualifier`,
  });